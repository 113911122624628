import React from 'react';

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/
//import Grid        from '@material-ui/core/Grid';
//import Container   from '@mui/material/Container';

import {
    // Flexbox,
    PageHeader,
    PageContent,
    // PageFooter,
    // FooterWithNavBoxes,
    Logotype,
} from '../StarberryComponentsMui';
import Grid from '@mui/material/Grid';

const Layout = ({className, children}) => {
    // @TODO: could probably use some sort of routing thing to supply data
    // into <Breadcrumbs />
    //
    return (
        <Grid container className={className}>
            <PageHeader>
                <Logotype />
            </PageHeader>

            <PageContent>
                {children}
            </PageContent>

            {/* <PageFooter>
                <FooterWithNavBoxes />
            </PageFooter> */}
        </Grid>
    );
};

export default React.memo(Layout);
